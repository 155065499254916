<section class="select-brand-container">
    <div class="">
      <div class="text-center">
        <img
        src="../../assets/images/ABBOTT LOGO-01.svg"
        class="w-125"
        
      />
        <img
          src="../../assets/images/Group 5170 (3).svg"
          class="w-75"
          alt="Rewards"
        />
        <p class="mb-3  txt">
            <strong class="blue">
                You got  <span class="light-blue">50 points</span> for signing up!</strong>
          </p>
        <p class="txt1">
          Choose the brand where you wish to credit the points to:
        </p>
        <select name="brand" id="brand" class="select mt pl10 form-control" >
          <option value="ensureGold" class="pl20"> Ensure Gold<sup>®</sup></option>
          <!-- <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option> -->
        </select>
        <button
          type="button"
          class="primaryBtn btn-white mt-4"
          [routerLink]="['/points-added']"
        >
          Confirm
        </button>
      </div>
    <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit 0009 s. 2022</p>
  </div>
</section>
