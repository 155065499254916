// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // 'https://apac-s3wrapper.capillarytech.com/auth/v1/token/generate'
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',

  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  //OAUTH_TOKEN:'https://cap-api-eu.sttarter.com/v3',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',

  BRAND: 'ABBOTTPHAARUAT',
  // BRAND: 'ABBOTTPHAARDEMO',
  //  DEVICE_ID: '1234551231',
  DEVICE_ID: '1234567',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  //TILL_ID: 'abbottphdemo',
  TILL_ID: 'abbott.ph.demo.1',
  COUNTRY_CODE: 'PH-AAR',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 10,
  MINMOBNUM: 10,
  COUPON_BASE_URL: 'https://gft.ph/'
};

// https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/skupoints

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
