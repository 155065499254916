import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FamilyRewardsComponent } from "./about-rewards-programme/family-rewards/family-rewards.component";
import { FaqComponent } from "./about-rewards-programme/faq/faq.component";
import { PrivacyPolicyComponent } from "./about-rewards-programme/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./about-rewards-programme/terms-conditions/terms-conditions.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { InitialDashboardComponent } from "./initial-dashboard/initial-dashboard.component";
import { ForgotPasswordComponent } from "./login-signup/forgot-password/forgot-password.component";
import { LoginSignupComponent } from "./login-signup/login-signup/login-signup.component";
import { LoginComponent } from "./login-signup/login/login.component";
import { OtpComponent } from "./login-signup/otp/otp.component";
import { SignUpComponent } from "./login-signup/sign-up/sign-up.component";
import { SignupSuccessComponent } from "./login-signup/signup-success/signup-success.component";
import { BuyLocalComponent } from "./products/buy-local/buy-local.component";
import { PediasureGoldDetailComponent } from "./products/pediasure-gold-detail/pediasure-gold-detail.component";
import { PediasureGoldSucroseFreeComponent } from "./products/pediasure-gold-sucrose-free/pediasure-gold-sucrose-free.component";
import { PediasureReadyToDrinkComponent } from "./products/pediasure-ready-to-drink/pediasure-ready-to-drink.component";
import { ProductDetailComponent } from "./products/product-detail/product-detail.component";
import { ProductListComponent } from "./products/product-list/product-list.component";
import { TestimonialComponent } from "./products/testimonial/testimonial.component";
import { AccountComponent } from "./profile/account/account.component";
import { ChangeMobileComponent } from "./profile/account/change-mobile/change-mobile.component";
import { ChangePasswordComponent } from "./profile/account/change-password/change-password.component";
import { EditaccountComponent } from "./profile/account/editaccount/editaccount.component";
import { ManageAddressComponent } from "./profile/account/manage-address/manage-address.component";
import { TransactionHistoryComponent } from "./profile/account/transaction-history/transaction-history.component";
import { ProfileComponent } from "./profile/profile.component";
import { QrCodeScannerComponent } from "./qr-code-scanner/qr-code-scanner.component";
import { RewardsCheckoutComponent } from "./rewards-checkout/rewards-checkout.component";
import { LandingComponent } from "./rewards/landing/landing.component";
import { RewardsBrandSelectionComponent } from "./rewards/landing/rewards-brand-selection/rewards-brand-selection.component";
import { CameraComponent } from "./scan/camera/camera.component";
import { DashboardComponent } from "./scan/dashboard/dashboard.component";
import { DisclaimerComponent } from "./scan/disclaimer/disclaimer.component";
import { SuccessComponent } from "./scan/success/success.component";
import { ValidateReceiptComponent } from "./validate-receipt/validate-receipt.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { TestQrComponent } from "./test-qr/test-qr.component";
import { BlockNoticeComponent } from "./block-notice/block-notice.component";
import { SiteSwitcherComponent } from "./site-switcher/site-switcher.component";
import { AboutEnsureGoldComponent } from "./ensure/about-ensure-gold/about-ensure-gold.component";
import { ProductBenefitsComponent } from "./ensure/product-benefits/product-benefits.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { RewardCheckoutComponent } from "./reward-checkout/reward-checkout.component";
import { PointsAddedComponent } from "./points-added/points-added.component";
import { SelectBrandComponent } from "./select-brand/select-brand.component";
import { SelectScannerComponent } from "./select-scanner/select-scanner.component";
import { EnsureGScannerComponent } from "./ensure-g-scanner/ensure-g-scanner.component";
import { SignupScanSuccessComponent } from "./signup-scan-success/signup-scan-success.component";
import { RewardDetailsComponent } from "./reward-details/reward-details.component";
import { RewardRedemptionSuccessfulComponent } from "./reward-redemption-successful/reward-redemption-successful.component";

const routes: Routes = [
  {
    path: "",
    component: InitialDashboardComponent,
    pathMatch: "full",
  },
  // {
  //   path: '',
  //   component: WelcomeComponent,
  // },
  {
    path: "test-qr",
    component: TestQrComponent,
  },
  {
    path: "welcome",
    component: WelcomeComponent,
  },
  {
    path: "coming-soon",
    component: ComingSoonComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "signup",
    component: SignUpComponent,
  },
  {
    path: "otp",
    component: OtpComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
  },
  {
    path: "profile/account",
    component: AccountComponent,
  },
  {
    path: "profile/edit",
    component: EditaccountComponent,
  },
  {
    path: "profile/change-mobile",
    component: ChangeMobileComponent,
  },
  {
    path: "profile/change-password",
    component: ChangePasswordComponent,
  },
  {
    path: "profile/manage-address",
    component: ManageAddressComponent,
  },
  {
    path: "profile/transaction-history",
    component: TransactionHistoryComponent,
  },
  {
    path: "about/family",
    component: FamilyRewardsComponent,
  },
  {
    path: "about/faq",
    component: FaqComponent,
  },
  {
    path: "about/terms",
    component: TermsConditionsComponent,
  },
  {
    path: "about/privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "products",
    component: ProductListComponent,
  },
  {
    path: "product-detail",
    component: ProductDetailComponent,
  },
  {
    path: "product-testimonial",
    component: TestimonialComponent,
  },
  {
    path: "buy-local",
    component: BuyLocalComponent,
  },
  {
    path: "rewards",
    component: LandingComponent,
  },
  {
    path: "rewards/brand",
    component: RewardsBrandSelectionComponent,
  },
  {
    path: "rewards/checkout",
    component: RewardsCheckoutComponent,
  },
  {
    path: "scan/camera",
    component: CameraComponent,
  },
  {
    path: "scan/success",
    component: SuccessComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "disclaimer",
    component: DisclaimerComponent,
  },
  {
    path: "login-signup",
    component: LoginSignupComponent,
  },
  {
    path: "signup-success",
    component: SignupSuccessComponent,
  },
  {
    path: "qr-scanner",
    component: QrCodeScannerComponent,
  },
  {
    path: "pediasure-gold",
    component: PediasureGoldDetailComponent,
  },
  {
    path: "pediasure-ready-to-drink",
    component: PediasureReadyToDrinkComponent,
  },
  {
    path: "pediasure-gold-sucrose-free",
    component: PediasureGoldSucroseFreeComponent,
  },
  {
    path: "validate-receipt",
    component: ValidateReceiptComponent,
  },
  {
    path: "block-notice",
    component: BlockNoticeComponent,
  },
  {
    path: "site-switcher",
    component: SiteSwitcherComponent,
  },
  {
    path: "about-ensure-gold",
    component: AboutEnsureGoldComponent,
  },
  {
    path: "product-benefits",
    component: ProductBenefitsComponent,
  },
  {
    path: "contact-us",
    component: ContactUsComponent,
  },
  {
    path: "reward/checkout",

    component: RewardCheckoutComponent,
  },
  {
    path: "select-brand",
    component: SelectBrandComponent,
  },
  {
    path: "points-added",
    component: PointsAddedComponent,
  },

  {
    path: "select-scanner",
    component: SelectScannerComponent,
  },
  {
    path: "ensure-gold-scanner",
    component: EnsureGScannerComponent,
  },
  {
    path: "signup-scan-success",
    component: SignupScanSuccessComponent,
  },
  {
    path: "reward/details",
    component: RewardDetailsComponent,
  },
  {
    path: "reward/redemption/successful",
    component: RewardRedemptionSuccessfulComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
