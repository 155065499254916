import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
   title = 'Abbott-SG';
  submittedEmailForm = false;
  emailForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  get emailFormVal() {
    return this.emailForm.controls;
  }

  onSubmitEmailForm() {
    this.submittedEmailForm = true
    if (this.emailForm.invalid) {
      return
    } else {
      console.log(this.emailForm.value)
    }
  }

}
