<section class="globalContainer px15 py-4 bg-white">
  <div class="disclaimer mt-15vh text-center px30">
    <div class="success-img">
      <img src="../../../assets/images/new/success.png" alt="Success" />
      <p>
        <strong>+50</strong>
        <span>POINTS</span>
      </p>
    </div>
    <img
      src="../../../assets/images/new/ensurelogo.png"
      alt="Pediasure"
      class="mt-2 mb-4"
    />
    <!-- <h4 class="font-darkblue fW600 fs32 mb-1 mt-4">POINTS</h4> -->
    <p class="mb-4">
      <strong class="light-blue">50 Points</strong> will be added to your
      account once verified.
    </p>
    <p class="mb-5">
      Verification may take up to 5 working days. Please keep your receipt for 2
      weeks in case we may need your proof of purchase.
    </p>
    <div class="btnSection">
      <button
        type="button"
        class="primaryBtn mb-3"
        (click)="onClickDashborad()"
      >
        Proceed to dashboard
      </button>
      <button
        type="button"
        class="primaryBtn btn-white mt-2s"
        (click)="onClickTransactionHistory()"
      >
        Review transaction history
      </button>
    </div>
  </div>
</section>
