<app-header title="Edit account information"></app-header>
<section class="globalContainer px15 py-5">
  <div class="editAccount px15">
    <div class="formContainer edit-account-form">
      <form
        [formGroup]="editAccountForm"
        (ngSubmit)="onSubmitEditAccountForm()"
      >
        <div class="fieldset">
          <div class="field">
            <label for="name">Full Name</label>
            <input
              formControlName="fullName"
              name="name"
              type="text"
              placeholder="Enter Full Name"
              [ngClass]="{
                'is-invalid':
                  submittedAccountForm && editAccountFormVal.fullName.errors
              }"
            />
          </div>
          <div
            *ngIf="submittedAccountForm && editAccountFormVal.fullName.errors"
            class="error-msg"
          >
            <span *ngIf="editAccountFormVal.fullName.errors.required"
              >Please enter full name</span
            >
          </div>
          <div class="field">
            <label for="email">Email<span>(optional)</span></label>
            <input formControlName="email" name="email" type="email" />
          </div>
        </div>
        <div class="field mt50">
          <button type="submit" class="primaryBtn Btn">Save</button>
        </div>
      </form>
    </div>
  </div>
  <app-menu [customerNamePresent]="customerNamePresent"></app-menu>
</section>
