import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {

  constructor() { }
  
  notifyError(error, payload?){
    if(!environment.production){
      return;
    }
    Bugsnag.notify(JSON.stringify(error))
    if(payload){
        Bugsnag.notify(JSON.stringify(payload))
    }
  }
}
