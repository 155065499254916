import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-scan-success',
  templateUrl: './signup-scan-success.component.html',
  styleUrls: ['./signup-scan-success.component.scss']
})
export class SignupScanSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
