<app-header title="Forgot Password"></app-header>
<section class="forgotPasswordContainer mt-15vh" *ngIf="showForgotPasswordForm">
  <h4 class="text-center fs20 font-darkblue">Forgot password</h4>
  <p class="mt15 text-center mb-5 mx-5">
    Enter your mobile number to reset the password
  </p>
  <form
    [formGroup]="forgotPasswordForm"
    (ngSubmit)="onSubmitForgotPasswordForm()"
    class="form-container"
  >
    <div class="position-relative">
      <div class="form-group">
        <label>Mobile number</label>
        <input
          type="number"
          formControlName="mobileNumber"
          class="form-control mobNumber"
          placeholder="9xx-xxxx-xxxx"
          [ngClass]="{
            'is-invalid':
              submittedForgotPasswordForm &&
              forgotPasswordVal.mobileNumber.errors
          }"
        />
        <span class="mobPlaceholder"
          ><img src="../../../assets/images/Flag-Philippines.png" />+63
          <img src="../../../assets/images/down-arrow.png" />
        </span>
        <img
          *ngIf="forgotPasswordVal.mobileNumber.status == 'VALID'"
          class="mobVerified"
          src="../../../assets/images/Group 4868.svg"
        />
      </div>
    </div>
    <div class="error" *ngIf="nonRegisteredUser">User does not exist.</div>

    <div
      *ngIf="
        submittedForgotPasswordForm && forgotPasswordVal.mobileNumber.errors
      "
      class="error"
    >
      <span *ngIf="forgotPasswordVal.mobileNumber.errors.required"
        >Please enter mobile number</span
      >
      <span *ngIf="forgotPasswordVal.mobileNumber.errors.pattern"
        >Invalid number</span
      >
    </div>

    <div class="text-center mt50">
      <button class="btn btn-primary me-2" type="submit">Submit</button>
    </div>
  </form>
</section>

<section class="resetPasswordContainer mt70" *ngIf="showResetPasswordForm">
  <div>
    <h4 class="text-center fs20 font-darkblue">Reset your password</h4>
    <p class="mt15 text-center mb-5 mx-5">
      Enter your new password twice to confirm
    </p>
    <p class="passerror" *ngIf="passwordError">
      Current password and old password cannot be same.
    </p>
  </div>
  <form
    [formGroup]="resetPasswordForm"
    (ngSubmit)="onSubmitResetPasswordForm()"
    class="form-container"
  >
    <div class="position-relative mt20">
      <div class="form-group">
        <label>New Password</label>
        <input
          (ngModelChange)="onPasswordCheckPattern($event)"
          [type]="showNewPasswordText ? 'text' : 'password'"
          formControlName="newPassword"
          class="form-control position-relative"
          placeholder="Enter your password"
          [ngClass]="{
            'is-invalid':
              submittedResetPasswordForm && resetPasswordVal.newPassword.errors
          }"
        />
        <ng9-password-strength-bar
          [passwordToCheck]="resetPasswordForm.value.newPassword"
          [customThresholds]="thresholds"
          [strengthLabels]="strengthLabels"
          (onStrengthChanged)="strengthChanged($event)"
        >
        </ng9-password-strength-bar>
        <div class="error" *ngIf="isPasswordPatternCorrect">
          Password does not meet requirement.
        </div>
        <span class="fs13 color-lightgrey p-10 d-block"
          >Password must be at least six characters, and contain one of the
          following: lower-case letter, upper-case letter, number and special
          character.</span
        >

        <img
          class="eye-icon"
          *ngIf="showEyeCloseIcon"
          src="../../../assets/images/Group 5035.svg"
          (click)="onClickShowNewPassword()"
        />
        <img
          class="eye-icon-open"
          *ngIf="showEyeOpenIcon"
          src="../../../assets/images/visibility.png"
          (click)="onClickShowNewPassword()"
        />
      </div>
    </div>

    <div
      *ngIf="submittedResetPasswordForm && resetPasswordVal.newPassword.errors"
      class="error"
    >
      <span *ngIf="resetPasswordVal.newPassword.errors.required"
        >Please enter password</span
      >
    </div>

    <div class="position-relative mt20">
      <div class="form-group">
        <label>Confirm Password</label>
        <input
          (ngModelChange)="onPasswordConfirmChanged($event)"
          [type]="showConfirmPasswordtext ? 'text' : 'password'"
          formControlName="confirmPassword"
          class="form-control position-relative"
          placeholder="Enter your password"
          [ngClass]="{
            'is-invalid':
              submittedResetPasswordForm &&
              resetPasswordVal.confirmPassword.errors
          }"
        />

        <img
          class="eye-icon"
          *ngIf="showEyeCloseIconConfirm"
          src="../../../assets/images/Group 5035.svg"
          (click)="onClickShowConfirmPassword()"
        />
        <img
          class="eye-icon-open"
          *ngIf="showEyeOpenIconConfirm"
          src="../../../assets/images/visibility.png"
          (click)="onClickShowConfirmPassword()"
        />
      </div>
    </div>
    <div class="flex error" *ngIf="passwordNotMaching">
      <img
        class="cross-icon"
        src="../../../assets/images/Group 4949.svg"
      /><span></span>Password does not match
    </div>
    <div
      *ngIf="
        submittedResetPasswordForm && resetPasswordVal.confirmPassword.errors
      "
      class="error"
    >
      <span *ngIf="resetPasswordVal.confirmPassword.errors.required"
        >Please enter confirm password</span
      >
    </div>

    <div class="text-center mt50">
      <button class="btn btn-primary me-2" type="submit">Submit</button>
    </div>
  </form>
</section>

<section class="otpContainer" *ngIf="showOTPForm">
  <div class="text-center">
    <div class="blue mb20">OTP verification</div>
    <div>
      Please enter the OTP to confirm password change for the Abbott Family
      Rewards program.
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      <form
        [formGroup]="registerOtpForm"
        (ngSubmit)="onSubmitRegOtpForm()"
        class="otpForm"
      >
        <div class="form-group text-center mt12">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 6, allowNumbersOnly: true }"
            [ngClass]=""
            #ngOtpInput
          >
          </ng-otp-input>

          <div *ngIf="invalidOtp" class="error mt20">
            The OTP entered is incorrect
          </div>
          <span class="px-2 text-center d-block mt12">
            <div
              [class.red]="this.otpColor == 'red'"
              [class.black]="this.otpColor == 'black'"
            >
              <span class="contentSmall">
                <countdown
                  #cd
                  [config]="{ leftTime: 60, format: 'mm:ss', notify: 0 }"
                  (event)="handleEvent($event)"
                ></countdown> </span
              ><span> left </span>
            </div>
          </span>

          <span class="px-2 text-center d-block mt40"
            >I didnt receive the code.<span class="blue" (click)="resendOTP()"
              >Resend OTP</span
            ></span
          >
        </div>

        <div class="text-center mt50">
          <button class="btn btn-primary me-2" type="submit">Confirm</button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="success-screen" *ngIf="showSuccessScreen">
  <h4 class="text-center blue fW900">Success!</h4>
  <div class="text-center">
    Your password has been successfully rest.You may now login using your new
    password
  </div>
  <div class="text-center mt50">
    <button class="btn btn-primary me-2" (click)="onClickLogin()">
      Back to login
    </button>
  </div>
</section>
