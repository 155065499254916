import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnChanges {
  @ViewChild("notLoginOrSignup", { static: true })
  notLoginOrSignup: ModalDirective;
  @ViewChild("button") button!: ElementRef;

  isTransactionsVisited = false;
  isRewardsVisited = false;
  @Input() customerNamePresent: any;
  @Input() rewardVisited = false;
  @Input() transactionsVisited = false;
  url: string;
  @Input() brand: any;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes["rewardVisited"]) {
      this.rewardVisited = changes["rewardVisited"].currentValue;
      console.log("reward: ", this.rewardVisited);
    }
    if (changes["transactionsVisited"]) {
      this.transactionsVisited = changes["transactionsVisited"].currentValue;
      console.log("transactions: ", this.transactionsVisited);
    }
    if (changes["brand"]) {
      this.brand = changes["brand"].currentValue;
      console.log("brand: ", this.brand);
    }
  }

  ngOnInit(): void {
    console.log(this.brand);
    this.route.url.subscribe((url) => {
      this.url = url[0].path;
      console.log(this.url);
    });
  }

  onClickProfile() {
    if (this.customerNamePresent) {
      this.router.navigate(["profile"]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickScan() {
    if (this.customerNamePresent) {
      this.isRewardsVisited = false;
      this.isTransactionsVisited = false;
      this.router.navigate(["qr-scanner"]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickRewards() {
    if (this.customerNamePresent) {
      this.brand = sessionStorage.getItem('currentBrand').toLowerCase();
      // this.isRewardsVisited = this.brand ? true : false;
      // this.isTransactionsVisited = false;
      this.router.navigate(["rewards/brand", { brand: this.brand }]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickTransactions() {
    this.brand = sessionStorage.getItem('currentBrand').toLowerCase();
    console.log(this.brand);
    if (this.customerNamePresent) {
      // this.isRewardsVisited = false;
      // this.isTransactionsVisited = true;
      this.router.navigate([
        "profile/transaction-history",
        { brand: this.brand },
      ]);
    } else {
      this.notLoginOrSignup.show();
    }
  }

  onClickCancel() {
    this.notLoginOrSignup.hide();
  }
}
