
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../../environments/environment';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-change-mobile',
  templateUrl: './change-mobile.component.html',
  styleUrls: ['./change-mobile.component.scss']
})
export class ChangeMobileComponent implements OnInit {

  submittedNumberChangedForm: boolean = false;
  showEyeCloseIcon: boolean = true;
  showPasswordText: boolean = false;
  showEyeOpenIcon: boolean = false;
  invalidPassword: boolean;
  mobileNumber: any;
  password: any;
  invalidOtp: boolean = false;
  otp: string;
  otpColor: string;
  showCurrentPasswordText: boolean = false;
  showEyeCloseCurrentIcon: boolean = true;
  showNewPasswordText: boolean = false;
  showConfirmPasswordtext: any = false;

  showEyeOpenCurrentIcon: any = false;
  timeLeft: any;
  showSuccessScreen: boolean = false;
  showMobileSection: boolean = true;
  showPasswordSection: boolean = false;
  showOtpSection: boolean = false;
  submittedPasswordForm: boolean = false
  isPasswordPatternCorrect: boolean = false;
  showEyeCloseIconConfirm: boolean = true;
  showEyeOpenIconConfirm: boolean = false;

  changeNumberForm: FormGroup;
  changePasswordForm: FormGroup;
  otpForm: FormGroup;
  submittedOTPForm: boolean = false;
  newMobileNumber: any;
  changePasswordValues: any;
  passwordError: boolean = false;
  beginTimer: boolean = false;
  sessionId: any;
  passwordNotMaching: boolean = false;
  strength: number;
  newToken: any;
  currentPasswordNotMatching: boolean = false;


  // //mloginForm= new FormGroup({mobileNumber : new FormControl(),password:new FormControl()
  // })
  constructor(
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
    ) { }

  ngOnInit(): void {
    this.changeNumberForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(`^((\\+63-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
        ],
      ]
    })

    this.changePasswordForm = this.formBuilder.group({
      currentPassword: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$'),
          // Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$'),
        ],
      ],
      newPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$'),
        //Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]
      ],
      confirmNewPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[A-Z])(?=.*[0-9]).{6,}$'),
        //  Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]]
    }, {
      validators: MustMatch('newPassword', 'confirmNewPassword')

    })

    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],

    })
  }

  onPasswordCheckPattern($event) {
    this.isPasswordPatternCorrect = this.changePasswordForm.controls.newPassword.status == 'INVALID' ? true : false
  }

  onClickShowCurrentPassword() {
    this.showCurrentPasswordText = !this.showCurrentPasswordText;
    this.showEyeCloseCurrentIcon = !this.showEyeCloseCurrentIcon;
    this.showEyeOpenCurrentIcon = !this.showEyeOpenCurrentIcon
  }

  onClickShowNewPassword() {
    this.showNewPasswordText = !this.showNewPasswordText;
    this.showEyeCloseIcon = !this.showEyeCloseIcon;
    this.showEyeOpenIcon = !this.showEyeOpenIcon
  }

  onClickShowConfirmPassword() {
    this.showConfirmPasswordtext = !this.showConfirmPasswordtext
    this.showEyeCloseIconConfirm = !this.showEyeCloseIconConfirm
    this.showEyeOpenIconConfirm = !this.showEyeOpenIconConfirm
  }


  get editPasswordFormVal() {
    return this.changePasswordForm.controls;
  }

  get changeNumberFormVal() {
    return this.changeNumberForm.controls;
  }

  get changePasswordFormVal() {
    return this.changePasswordForm.controls
  }


  onClickEyeCloseIcon() {
    this.showEyeCloseIcon = false;
    this.showPasswordText = true;
    this.showEyeOpenIcon = true;
  }

  onClickEyeOpenIcon() {
    this.showPasswordText = false;
    this.showEyeCloseIcon = true;
    this.showEyeOpenIcon = false;
  }
  onOtpChange(otp) {
    console.log(otp)
    this.invalidOtp = false;
    this.otp = otp;
  }
  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft <= 10000) {
      this.otpColor = 'red'
    }
  }

  onClickLogin() {
    this.router.navigate(['/profile'])
  }

  onSubmitChangeNumberForm() {
    this.submittedNumberChangedForm = true;
    if (this.changeNumberForm.invalid) {
      return;
    } else {
      this.newMobileNumber = this.changeNumberForm.value.mobileNumber
      let newToken = sessionStorage.getItem('authToken')
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": this.newMobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "password": 'test123',
        "confirmPassword": 'test123',
      }
      this.spinner.show();
      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            if (res['user'].userRegisteredForPassword == true) {
              this.apiService.showToaster('user(number) already registered')
            } else {
              if (this.newMobileNumber != null) {
                let mobileNo = sessionStorage.getItem('mobileNo');
                let newMobNumber = JSON.stringify(this.newMobileNumber)
                console.log(newMobNumber)
                let updateMobile = {
                  "root": {
                    "customer": [
                      {
                        "identifier": 'mobile',
                        "old_value": '63' + mobileNo,
                        "new_value": '63' + this.newMobileNumber
                      }
                    ]
                  }
                }
                newMobNumber = '63' + this.newMobileNumber
                let oldMobNumber = mobileNo
                console.log("update mobile: ", updateMobile)

                this.apiService.updateCapCustomerIdentity(updateMobile, oldMobNumber, newToken)
                  .subscribe(res => {
                    this.spinner.hide()

                    if (res['status'].code == 200) {
                      let formData = {
                        "identifierType": "MOBILE",
                        "identifierValue": this.newMobileNumber,
                        "deviceId": env.DEVICE_ID,
                        "brand": env.BRAND,
                        "password": 'test123',
                        "confirmPassword": 'test123',
                      }
                      this.spinner.show();
                      this.apiService.tokenGenerate(formData).pipe(first())  //token generate api
                        .subscribe(res => {
                          this.spinner.hide()
                          if (res['status'].code == 200) {
                            this.sessionId = res['user'].sessionId;
                            sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
                            let token = sessionStorage.getItem('authToken')
                            this.showMobileSection = false;
                            this.showPasswordSection = true;
                          }
                        }, err => {
                          this.spinner.hide()
                          console.log(err)
                          this.bsService.notifyError(err, formData);
                        })
                    }
                  }, err => {
                    this.spinner.hide()
                    console.log(err)
                    this.bsService.notifyError(err, updateMobile);
                  })
              }
            }
          }
          else {
            return
          }
        }, err => {
          this.spinner.hide();
          console.log(err)
          this.bsService.notifyError(err, formData);
        })
    }
  }

  onSubmitPasswordForm() {
    this.submittedPasswordForm = true;
    if (this.changePasswordForm.invalid) {
      return
    } else {
      let otpGenerateData = {
        "identifierType": "MOBILE",
        "identifierValue": this.newMobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId
      }
      this.spinner.show();
      this.apiService.otpGenerate(otpGenerateData).pipe(first())
        .subscribe(res => {
          if (res['status'].code == 200) {
            this.spinner.hide()
            this.showPasswordSection = false
            this.showOtpSection = true;
          }
        }, err => {
          this.spinner.hide()
          console.log(err)
          this.bsService.notifyError(err, otpGenerateData);
        })
    }

  }

  onPasswordConfirmChanged($event) {
    let password = this.changePasswordForm.controls.newPassword.value
    if ($event != password && password.length > 0) this.passwordNotMaching = true
    else this.passwordNotMaching = false
  }

  strengthChanged(strength: number) {
    this.strength = strength;
  }

  onSubmitOtpForm() {
    this.submittedOTPForm = true;
    if (this.otp == undefined || this.otp.length < 6) {
      return;
    } else {

      // api call
      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": this.newMobileNumber,
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "otp": this.otp
      }
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe((res) => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.spinner.hide()
            sessionStorage.setItem('authToken', res['auth'].token);
            sessionStorage.setItem('authKey', res['auth'].key)

            let getauthkey = sessionStorage.getItem('authKey')
            let newToken = sessionStorage.getItem('authToken')
            let getMobile = this.newMobileNumber

            // this.spinner.show();
            // let OTPformData = {
            //   mobile: getMobile,
            //   otp_token: newToken,
            //   otp_key: getauthkey
            // }

            // this.apiService.postAuthToken(OTPformData).subscribe((res: any) => {
            //   this.spinner.hide();
            //   console.log(res)
            // }, err => {
            //   console.log(err)
            //   this.spinner.hide()
            // })

            let formData = {
              "identifierType": "MOBILE",
              "identifierValue": this.newMobileNumber,
              "deviceId": env.DEVICE_ID,
              "brand": env.BRAND,
              "password": 'test123',
              "newPassword": this.changePasswordForm.controls.newPassword.value,
              "confirmPassword": this.changePasswordForm.controls.confirmNewPassword.value,
              "token": newToken
            }
            this.spinner.show();
            this.apiService.changePassword(formData).pipe(first())
              .subscribe(res => {
                this.spinner.hide();
                if (res['status'].code == 200) {
                  this.spinner.hide()
                  let userpasswd = sessionStorage.setItem('userPassword', this.changePasswordForm.controls.confirmNewPassword.value);
                  sessionStorage.setItem('mobileNo', this.newMobileNumber)
                  this.showOtpSection = false;
                  this.showSuccessScreen = true
                } else {
                  this.spinner.hide()
                  console.log(res['status'].message)
                }
              }, err => {
                this.spinner.hide();
                console.log(err)
                this.bsService.notifyError(err, formData);
              })
          }
          else {
            this.spinner.hide()
            console.log('Hello')
          }
        }, err => {
          console.log(err)
          this.spinner.hide()
          this.bsService.notifyError(err, otpSubmitData);
        })
    }
  }

  changeCurrentPassword($event) {
    let currentValue = $event
    let password = sessionStorage.getItem('userPassword')
    this.currentPasswordNotMatching = currentValue != password ? true : false
  }

}
