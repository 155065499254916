<section class="globalContainer px15 py-4 bg-white">
  <div class="disclaimer mt-30vh text-center">
    <h5 class="blue mb-3"><img class="mtm-12" src="../../../assets/images/new/info.png" alt="info" /> <strong> IMPORTANT</strong> </h5>
    <p class="mb-0">
      By clicking
      <strong class="blue">Continue</strong> you are confirming the purchase of this Abbott product. 
      </p>
      <p class="mb-0">Any fraudulent activities captured by the system will result in freezing of your account and the proof of purchase will be required.</p>
      <p>If you have not purchased the product, please click <strong class="blue">Exit </strong>.</p>
      
     
  
    <div class="btnSection">
      <button type="button" class="primaryBtn" (click)="onClickContinue()">
        Continue
      </button>
      <button
        type="button"
        class="primaryBtn btn-white mt-2"
        (click)="onClickExit()"
      >
        Exit
      </button>
      <!-- <section class="globalContainer  px15 py-4 bg-white">
    <div class="disclaimer mt-30vh text-center px30">
        <h5 class="font-darkblue fs20 fW600 mb-4"><img class="v-top" src="../../../assets/images/warning-blue.png" /> IMPORTANT</h5>
        <p class="mb-2">By clicking “<a class="font-blue fW600 text-no-underline">Continue</a>”, you are confirming the purchase of this Abbott product.</p> 
        <p class="mb-2">Any fraudulent activities captured by the system will result in freezing of your account and the proof of purchase will be required.</p>
        <p class="mb-2">If you have not purchased the product, please click “<a class="font-blue fW600 text-no-underline">Exit</a>”.</p>
        <div class="btnSection mt40">
            <button type="button" class="primaryBtn">Continue</button>
            <button type="button" class="primaryBtn btn-white mt-2">Exit</button>
        </div>
    </div>
    </section> -->
      <!-- </div> -->

      <!-- </section> -->
    </div>
  </div>
</section>
