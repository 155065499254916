<section class="brand-container">
  <div class="user-name text-right">
    <div *ngIf="customerNamePresent">
      <p   class="fs20 mb-0"><span [routerLink]="['/profile']">{{ customerName }}</span></p>
      <!-- <div class="d-flex justify-content-end">
        <a  [routerLink]="['/profile']" class="my-profile fs10 mx-1">My Profile </a>
        <img src="../../assets/images/chevron-right-1px.svg" />
      </div> -->
    </div>
    <div *ngIf="!customerNamePresent">
      <div
        class="d-flex justify-content-end signup"
        [routerLink]="['/login-signup']"
      >
        <span class="user-name fs20 mx-1">Sign up / login </span>
        <img src="../../assets/images/chevron-right-1px.svg" />
      </div>
    </div>
  </div>
  <div>
    <div class="image">
      <img  src="../../../assets/images/ABBOTT LOGO-01.png" />
    </div>

    <div class="image-container mt-4">
      <div class="brand-box row">
        <div class="col ml2">
          <div class="ensure" [routerLink]="['/dashboard', { name: 'ensure' }]">
            <div class="content">
              <p class="text-center mb-2">Reward Points</p>
              <strong class="text-center">{{loyaltyPoints}}</strong>
            </div>
          </div>
        </div>

        <!-- below div should be only shown when glucerna is DISABLED -->
        <div class="col">
          <div class="glucerna-disabled">
            <div class="">
              <strong class="text-center fs8 mb-1">COMING SOON</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="white-box">
    <a [routerLink]="['/about/family']" class="text-decoration-none"
      >About Abbott Alaga Rewards</a
    >
    <img src="../../assets/images/dot.svg" />
    <a [routerLink]="['/contact-us']" class="text-decoration-none"
      >Contact Us</a
    >
  </div>
</section>
