import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  iSprintData: any;
  latitude: string;
  longitude: string;
  confidence: string;
  scanCode: string;
  skuPoint: any;
  constructor(
    private router: Router, 
    private apiService: ApiService,
    private bsService: BugsnagService
    ) { }

  ngOnInit(): void {
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData, 'this.iSprintData ')
    this.scanCode = sessionStorage.getItem('scannedCode');

    console.log(this.scanCode, 'this.scanCode')
    this.apiService.getSkuPoints(this.iSprintData.sku).subscribe(
      (res: any) => {

        this.skuPoint = res.data[0]?.points;
        console.log(this.skuPoint, 'skuPoint == ')
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  onClickDashborad() {
    this.router.navigate(['dashboard'])
  }

  onClickTransactionHistory() {
    this.router.navigate(['profile/transaction-history'])

  }

}
