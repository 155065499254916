import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  submittedForgotPasswordForm: boolean = false;
  submittedResetPasswordForm: boolean = false;
  showResetPasswordForm: boolean = false;
  showForgotPasswordForm: boolean = true
  showOTPForm: boolean = false;
  registerOtpForm: FormGroup;
  invalidOtp: boolean = false;
  otp: string;
  submitRegOtpForm: boolean = false;
  timeLeft: any;
  showSuccessScreen: boolean = false;
  showNewPasswordText: boolean = false;
  showEyeCloseIcon: boolean = true;
  showEyeOpenIcon: any = false;
  showConfirmPasswordtext: any = false;
  showEyeCloseIconConfirm: boolean = true;
  showEyeOpenIconConfirm: boolean = false;
  mobileNo: any;
  userNotFound: boolean;
  nonRegisteredUser: boolean;
  sessionId: any;
  beginTimer: boolean;
  passwordError: boolean;
  otpColor: string;
  showResendOtp: boolean;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  public barLabel: string = "Password strength:";
  public strengthLabels = ['', 'WEAK', 'NORMAL', 'STRONG', 'GREAT!'];
  public thresholds = [90, 75, 45, 25];
  strength: number;
  passwordNotMaching: boolean = false;
  isPasswordPatternCorrect: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
    ) { }



  ngOnInit(): void {
    this.registerOtpForm = this.formBuilder.group({
      otp1: ['', Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(`^((\\+65-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`),
        ]
      ]
    })

    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
        // Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]],
      confirmPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?=.*[0-9]).{6,}$'),
        //  Validators.pattern('^(?=.*?[A-Z])((?=.*[0-9])|(?=.*[A-Z])|(?=.*[a-z])|(?=.*?[#?!@$%^&*-])).{6,}$')
      ]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')

      })
  }

  get resetPasswordVal() {
    return this.resetPasswordForm.controls;
  }

  onPasswordCheckPattern($event) {
    this.isPasswordPatternCorrect = this.resetPasswordForm.controls.newPassword.status == 'INVALID' ? true : false
  }

  onPasswordConfirmChanged($event) {
    let password = this.resetPasswordForm.controls.newPassword.value
    if ($event != password && password.length > 0) this.passwordNotMaching = true
    else this.passwordNotMaching = false
  }


  resendOTP() {
    this.ngOtpInput.setValue('');
    this.countdown.restart()
    this.otpColor = "black"
    this.spinner.show();
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: this.resetPasswordForm.value.newPassword,
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
    };


    this.spinner.show();
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {

        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
        }
        this.showResendOtp = false;
        //this.ngOtpInput.setValue('');

        let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
        //this.tokenReGenerate();
        let otpGenerateData = {
          mobile: getMobile,
          deviceId: env.DEVICE_ID,
          brand: env.BRAND,
          sessionId: sessionIdValue,
        };
        this.spinner.show();

        this.apiService
          .otpGenerate(otpGenerateData)
          .pipe(first())
          .subscribe(
            (res) => {
              //window.alert('OTP sent');
              this.spinner.hide();
              if (res['status'].code == 200) {
              } else if (res['status'].code == 401) {
                this.apiService.logout();
              } else {
                this.apiService.showToaster(res['status'].message);
              }
            },
            (err) => {
              this.spinner.hide();
              console.log(err, '=== OTP gererate error list =======');
              this.bsService.notifyError(err, otpGenerateData);
            }
          );
      }, err => {
        console.log(err),
          this.spinner.hide()
          this.bsService.notifyError(err, formData);
      });
  }


  handleEvent(event) {
    this.timeLeft = event.left;
    if (this.timeLeft <= 10000) {
      this.otpColor = 'red'
    }
  }

  onOtpChange(otp) {
    console.log(otp)
    this.invalidOtp = false;
    this.otp = otp;
  }

  strengthChanged(strength: number) {
    this.strength = strength;
  }

  onSubmitRegOtpForm() {
    this.submitRegOtpForm = true;
    if (this.otp == undefined || this.otp.length < 4) {
      console.log('otp err');
      return;
    } else {

      console.log(this.registerOtpForm.value);

      let otpSubmitData = {
        "identifierType": "MOBILE",
        "identifierValue": JSON.stringify(this.mobileNo),
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "otp": this.otp
      }
      this.spinner.show();
      this.apiService.otpValidate(otpSubmitData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            sessionStorage.setItem('authToken', res['auth'].token)
            sessionStorage.setItem('authKey', res['auth'].key)
            this.showSuccessScreen = true
            this.showOTPForm = false
            // this.router.navigateByUrl('/login')
            this.registerOtpForm.reset();

          } else {
            this.invalidOtp = true;

          }
        }, err => {
          console.log(err)
          this.spinner.hide()
          this.bsService.notifyError(err, otpSubmitData);
        })
    }

  }

  onClickLogin() {
    this.router.navigate(['/login'])
  }

  onClickShowNewPassword() {
    this.showNewPasswordText = !this.showNewPasswordText;
    this.showEyeCloseIcon = !this.showEyeCloseIcon;
    this.showEyeOpenIcon = !this.showEyeOpenIcon
  }

  onClickShowConfirmPassword() {
    this.showConfirmPasswordtext = !this.showConfirmPasswordtext
    this.showEyeCloseIconConfirm = !this.showEyeCloseIconConfirm
    this.showEyeOpenIconConfirm = !this.showEyeOpenIconConfirm
  }


  get forgotPasswordVal() {
    return this.forgotPasswordForm.controls;
  }

  get regOtpFormVal() {
    return this.registerOtpForm.controls;
  }

  onSubmitForgotPasswordForm() {
    this.submittedForgotPasswordForm = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {

      console.log(this.forgotPasswordForm.value)
      let formValues = this.forgotPasswordForm.value
      this.mobileNo = formValues.mobileNumber
      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": JSON.stringify(this.mobileNo),
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
      }
      this.spinner.show();
      console.log("form: ", formData)
      this.apiService.tokenGenerate(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();

          if (res['status'].code == 200) {
            if (res['user'].userRegisteredForPassword == false) {

              this.userNotFound = true;
              this.nonRegisteredUser = false;
              //this.showResendOtp = false;
              console.log('user not registered')


            }


            else {
              this.spinner.hide();
              this.userNotFound = false;
              this.nonRegisteredUser = true;
              this.sessionId = res['user'].sessionId;
              sessionStorage.setItem('sessionID', JSON.stringify(this.sessionId));
              this.forgotPasswordForm.reset();
              this.submittedForgotPasswordForm = true
              this.showResetPasswordForm = true;
              this.showForgotPasswordForm = false;
            }
          } else if (res['status'].code == 1520) {
            this.spinner.hide();
            this.nonRegisteredUser = true;
          } else { }
        }, err => {
          console.log(err);
          this.spinner.hide()
          this.bsService.notifyError(err, formData);
        })
    }
  }

  onSubmitResetPasswordForm() {
    this.submittedResetPasswordForm = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {

      console.log(this.resetPasswordForm.value)

      let formValues = this.resetPasswordForm.value
      this.spinner.show();
      this.sessionId = JSON.parse(sessionStorage.getItem('sessionID'));

      let formData = {
        "identifierType": "MOBILE",
        "identifierValue": JSON.stringify(this.mobileNo),
        "deviceId": env.DEVICE_ID,
        "brand": env.BRAND,
        "sessionId": this.sessionId,
        "password": formValues.newPassword,
        "confirmPassword": formValues.confirmPassword
      }

      this.apiService.forgotPassword(formData).pipe(first())
        .subscribe(res => {
          this.spinner.hide();
          if (res['status'].code == 200) {
            this.showOTPForm = true;
            this.showResetPasswordForm = false;
            this.beginTimer = true;
            this.resetPasswordForm.reset();
            this.submittedResetPasswordForm = false;
          
            let otpformValue = this.registerOtpForm.value;

            let otpGenerateData = {
              "identifierType": "MOBILE",
              "identifierValue": this.mobileNo,
              "deviceId": env.DEVICE_ID,
              "brand": env.BRAND,
              "sessionId": this.sessionId
            }

            this.spinner.show();
            this.apiService.otpGenerate(otpGenerateData).pipe(first())

              .subscribe(res => {
                this.spinner.hide();
                if (res['status'].code == 200) {

                  let formValues = this.registerOtpForm.value;
                  console.log(this.registerOtpForm.value.otp, 'this.forgotOtpForm.value.otp--')

                }
              }, err => {
                this.spinner.hide();
                console.log(err, '=== OTP gererate error list =======')
                this.bsService.notifyError(err, otpGenerateData);
              })
          } else if (res['status'].code == 1527) {
            this.spinner.hide();
            this.passwordError = true;

          } else {
            this.spinner.hide();
            console.log(res['status'].message)
            // --------test-----------
            this.showOTPForm = true;
            this.showResetPasswordForm = false;
            this.beginTimer = true;
            this.resetPasswordForm.reset();
            this.submittedResetPasswordForm = false;
          }

        }, err => {
          this.spinner.hide();
          console.log(err)
          this.bsService.notifyError(err, formData);
        }
        )
    }
  }

}
