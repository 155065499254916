<section class="sign-up-container min-vh-100 ">
    <div>
    <div class="img1">
    <img class="text-center" src="../../assets/images/Group 5526.png" alt="Points added" />
    </div>
    <div  class="text text-center"><span><span class="font-blue fW600">50 points</span> added to your<span class="font-blue fW600"> Ensure Gold <sup>®</sup></span> account!</span>
    </div> 
      <div  class="text text-center">
      <p >
        Start scanning your purchases to accumulate more reward points.
      </p>
    </div> 
        <button
          type="button"
          class="btn btn-white mt-2"
          [routerLink]="['/site-switcher']"
        >
        Explore brands
        </button>

        <div> 
          <a [routerLink]="['/about/family']" class="url"> Learn more about  Abbott Alaga Rewards</a>
        </div>
        <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit 0009 s. 2022</p>
    </div>
       
  </section>
