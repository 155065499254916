import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-signup-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss'],
})
export class SignupSuccessComponent implements OnInit {
  scanCode: any;
  iSprintData: any;
  customerData: any;
  customerName: string;
  suspiciousCount: number;
  isScanCodeAvailable: any;
  invalidQR: any;
  customerNamePresent = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.scanCode = sessionStorage.getItem('scannedCode');
    this.isScanCodeAvailable = this.isEmpty(this.scanCode);
    this.invalidQR = sessionStorage.getItem('invalidQR')
    console.log(this.invalidQR, 'this.invalidQR')
    console.log(this.isScanCodeAvailable, 'this.isScanCodeAvailable')
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
  }

  isEmpty(scanCode) {
    if (scanCode == '' || scanCode == null || scanCode == undefined)
      return false;
    else return true;
  }

  onClickContinue() {
    this.router.navigate(['disclaimer']);
  }

  onClickScanNow() {
    this.router.navigate(['qr-scanner']);
  }

  onClickDashboard() {
    //this.router.navigate(['dashboard']);
    this.router.navigate(['site-switcher']);
  }
}
