<section class="globalContainer">
  <div class="dashboard-container profileBG">
    <div class="dasboard-profile-card pb-4">
      <div class="row mx-3 pb-4 pe-3">
        <div class="col-6">
          <img
            (click)="openHamberger()"
            src="../../../assets/images/new/berger.png"
            alt="img"
          />
        </div>
        <div class="col-6 text-right pr-0">
          <article>
            <div class="top" *ngIf="customerNamePresent">
              <p class="profile-name logLinkBig">
                <strong> {{ customerName }} </strong>
              </p>
              <div class="d-flex justify-content-end align-items-baseline">
                <p class="smallTxt" routerLink="/profile">My Profile</p>
                <img
                  class="ms-2"
                  src="../../../assets/images/chevron-right-yellow.svg"
                />
              </div>
            </div>
            <div *ngIf="!customerNamePresent">
              <a
                *ngIf="!customerNamePresent"
                class="color-yellow logLink"
                (click)="onClickAccoutLogin()"
                ><strong>Sign up / login > </strong></a
              >
            </div>
          </article>
        </div>
      </div>

      <div class="row mx-0 px30">
        <div class="col-6 point-text pl-0">
          <img
            class="mt-4"
            src="../../../assets/images/new/ensure-logo.png"
            alt="logo"
          />
        </div>
        <div class="col-6 text-right pr-0">
          <!-- <p class="point-text mt-3 mb-2">
            Reward points
          </p>
          <p class="points">4,100 </p>  -->

          <p class="point-text mt-3 mb-2" *ngIf="customerNamePresent">
            Reward points
          </p>
          <p class="points mt-1" *ngIf="customerNamePresent">
            {{ loyaltyPoints }}
          </p>
        </div>
      </div>
    </div>
    <div class="dashboard-page bg-lightBlue btl30 btr30">
      <p class="fs20">
        <strong class="blue">More about Ensure Gold<sup>®</sup></strong>
      </p>
      <div class="dashboard-card d-flex" (click)="onClickAboutEnsureGold()">
        <img src="../../../assets/images/Group 5577.svg" alt="Heart" />
        <p>About Ensure Gold<sup>®</sup></p>
      </div>
      <div class="dashboard-card d-flex" (click)="onClickProductBenefits()">
        <img src="../../../assets/images/Group 5578.svg" alt="Heart" />
        <p>Product Benefits</p>
      </div>
      <!-- <div class="dashboard-card d-flex mercury">
        <img src="../../../assets/images/Group 5579.svg" alt="Local" />
        <p>Mercury Drug E-card</p>
      </div> -->
    </div>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu
  [customerNamePresent]="customerNamePresent"
  [brand]="productName"
></app-menu>

<div
  bsModal
  #hambergermenu="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="modal-blue-bg">
          <div class="d-flex justify-content-between">
            <img
              (click)="closeHamberger()"
              src="../../../assets/images/Group 5176.svg"
            />

            <div class="" *ngIf="customerNamePresent">
              <p class="user-name fs20 m-0">{{ customerName }}</p>
              <!-- <div
                class="d-flex justify-content-end"
                [routerLink]="['/profile']"
              >
                <a class="my-profile fs10 mx-1 text-right">My Profile </a>
                <img src="../../assets/images/chevron-right-1px.svg" />
              </div> -->
            </div>
            <div *ngIf="!customerNamePresent">
              <a
                *ngIf="!customerNamePresent"
                class="logLink"
                (click)="onClickAccoutLogin()"
                ><strong>Sign up / login > </strong></a
              >
            </div>
          </div>
          <div class="mt-4">
            <div class="text-left mx-3">
              <img  src="../../../assets/images/ABBOTT LOGO-01.png" />
            </div>

            <div class="image-container mt-4">
              <div class="brand-box row">
                <div class="col pr-0 pl-0">
                  <div class="ensure" (click)="onClickBrand('ensure')">
                    <div class="content">
                      <p class="text-center mb-2">Reward Points</p>
                      <strong class="text-center">{{ loyaltyPoints }}</strong>
                    </div>
                  </div>
                </div>

                <!-- below div should be only shown when glucerna is DISABLED -->
                <div class="col">
                  <div
                    class="glucerna-disabled"
                    (click)="onClickBrand('glucerna')"
                  >
                    <div class="">
                      <strong class="text-center fs8 mb-1">COMING SOON</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center bottom">
              Per DOH-FDA CFRR Promo Permit 0009 s. 2022
            </p>
          </div>
        </div>
        <div class="modal-white-bg">
          <a [routerLink]="['/about/family']">About Abbott Alaga  Rewards</a>
          <img src="../../assets/images/dot.svg" />
          <a [routerLink]="['/contact-us']"> Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</div>
