import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-points-added',
  templateUrl: './points-added.component.html',
  styleUrls: ['./points-added.component.scss']
})
export class PointsAddedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
