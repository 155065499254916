<app-header title="Change mobile number"></app-header>

<section class="login-container px30" *ngIf="showMobileSection">
  <form
    [formGroup]="changeNumberForm"
    class="login-Form mt40"
    (ngSubmit)="onSubmitChangeNumberForm()"
  >
    <div class="position-relative">
      <div class="form-group">
        <label>Mobile number</label>
        <input
          type="number"
          formControlName="mobileNumber"
          class="form-control mobNumber"
          placeholder="9xx-xxxx-xxxx"
        />
        <span class="mobPlaceholder"
          ><img src="../../../assets/images/Flag-Philippines.png" />+63
          <img src="../../../assets/images/down-arrow.png" />
        </span>
        <img
          *ngIf="changeNumberFormVal.mobileNumber.status == 'VALID'"
          class="correct-icon"
          src="../../../assets/images/Group 4868.svg"
        />
      </div>
    </div>

    <div
      *ngIf="
        submittedNumberChangedForm && changeNumberFormVal.mobileNumber.errors
      "
      class="error"
    >
      <span *ngIf="changeNumberFormVal.mobileNumber.errors.required"
        >Please enter mobile number</span
      >
      <span *ngIf="changeNumberFormVal.mobileNumber.errors.pattern"
        >Invalid number</span
      >
    </div>

    <div class="text-center mt40">
      <button class="btn btn-primary me-2" type="submit">
        <strong>Next</strong>
      </button>
    </div>
  </form>
</section>

<section class="login-container px30" *ngIf="showPasswordSection">
  <div class="editAccount changePassword px15">
    <div class="formContainer edit-account-form">
      <form
        [formGroup]="changePasswordForm"
        (ngSubmit)="onSubmitPasswordForm()"
      >
        <div class="fieldset">
          <div class="field position-relative">
            <label for="currentPassword" class="mb-0">Current password</label>
            <input
              (ngModelChange)="changeCurrentPassword($event)"
              [type]="showCurrentPasswordText ? 'text' : 'password'"
              formControlName="currentPassword"
              name="currentPassword"
              type="password"
              placeholder="**********"
              [ngClass]="{
                'is-invalid':
                  submittedChangePasswordForm &&
                  editPasswordFormVal.currentPassword.errors
              }"
            />
            <img
              class="eye-icon mt10"
              *ngIf="showEyeCloseCurrentIcon"
              src="../../../assets/images/Group 5035.svg"
              (click)="onClickShowCurrentPassword()"
            />
            <img
              class="eye-icon-open mt10"
              *ngIf="showEyeOpenCurrentIcon"
              src="../../../assets/images/visibility.png"
              (click)="onClickShowCurrentPassword()"
            />
          </div>

          <div class="flexc error-msg" *ngIf="currentPasswordNotMatching">
            <img
              class="cross-icon"
              src="../../../assets/images/Group 4949.svg"
            /><span></span>
            Incorrect current password
          </div>

          <div
            *ngIf="
              submittedChangePasswordForm &&
              editPasswordFormVal.currentPassword.errors
            "
            class="error-msg"
          >
            <span *ngIf="editPasswordFormVal.currentPassword.errors.required"
              >Please enter current password</span
            >
            <!-- <span *ngIf="editPasswordFormVal.currentPassword.errors.pattern"
              >Invalid password</span
            > -->
          </div>

          <div class="field position-relative">
            <label for="Newpassword" class="mb-0">New password</label>
            <input
              (ngModelChange)="onPasswordCheckPattern($event)"
              formControlName="newPassword"
              name="Newpassword"
              [type]="showNewPasswordText ? 'text' : 'password'"
              placeholder="**********"
              [ngClass]="{
                'is-invalid':
                  submittedChangePasswordForm &&
                  editPasswordFormVal.newPassword.errors
              }"
            />
            <ng9-password-strength-bar
              [passwordToCheck]="changePasswordForm.value.newPassword"
              [customThresholds]="thresholds"
              [strengthLabels]="strengthLabels"
              (onStrengthChanged)="strengthChanged($event)"
            >
            </ng9-password-strength-bar>
            <img
              class="eye-icon mt10"
              *ngIf="showEyeCloseIcon"
              src="../../../assets/images/Group 5035.svg"
              (click)="onClickShowNewPassword()"
            />
            <img
              class="eye-icon-open mt10"
              *ngIf="showEyeOpenIcon"
              src="../../../assets/images/visibility.png"
              (click)="onClickShowNewPassword()"
            />

            <div class="flex error" *ngIf="isPasswordPatternCorrect">
              <img
                class="cross-icon"
                src="../../../assets/images/Group 4949.svg"
              /><span></span>
              Password does not meet requirement.
            </div>

            <p class="mt-2">
              Password must be atleat 6 characters,and contain one of the
              following: lower-case letter, upper-case letter, number and
              special character.
            </p>
          </div>

          <div
            *ngIf="
              submittedChangePasswordForm &&
              editPasswordFormVal.newPassword.errors
            "
            class="error-msg"
          >
            <span *ngIf="editPasswordFormVal.newPassword.errors.required"
              >New password is required</span
            >
            <!-- <span *ngIf="editPasswordFormVal.newPassword.errors.pattern"
              >Invalid password</span
            > -->
          </div>

          <div class="field position-relative">
            <label for="ConfirmNewpassword" class="mb-0"
              >Confirm new password</label
            >
            <input
              (ngModelChange)="onPasswordConfirmChanged($event)"
              formControlName="confirmNewPassword"
              name="ConfirmNewpassword"
              [type]="showConfirmPasswordtext ? 'text' : 'password'"
              placeholder="**********"
              [ngClass]="{
                'is-invalid':
                  submittedChangePasswordForm &&
                  editPasswordFormVal.confirmNewPassword.errors
              }"
            />
            <img
              class="eye-icon mt10"
              *ngIf="showEyeCloseIconConfirm"
              src="../../../assets/images/Group 5035.svg"
              (click)="onClickShowConfirmPassword()"
            />
            <img
              class="eye-icon-open mt10"
              *ngIf="showEyeOpenIconConfirm"
              src="../../../assets/images/visibility.png"
              (click)="onClickShowConfirmPassword()"
            />

            <!-- <div class="flex-error" *ngIf="passwordNotMaching"
            
              >
              <img
        class="cross-icon"
        src="../../../assets/images/Group 4949.svg"
      /><span></span>Password does not match</div
            > -->

            <div class="flex error" *ngIf="passwordNotMaching">
              <img
                class="cross-icon"
                src="../../../assets/images/Group 4949.svg"
              /><span></span>Password does not match
            </div>

            <div
              *ngIf="
                submittedChangePasswordForm &&
                editPasswordFormVal.confirmNewPassword.errors
              "
              class="error"
            >
              <span
                *ngIf="editPasswordFormVal.confirmNewPassword.errors.required"
                >Confirm password is required</span
              >
              <span
                *ngIf="editPasswordFormVal.confirmNewPassword.errors.pattern"
                >Invalid password</span
              >
            </div>
          </div>
          <div class="field mt50">
            <button type="submit" class="primaryBtn Btn">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="otpContainer" *ngIf="showOtpSection">
  <div class="text-center">
    <div class="blue mb20"><strong> OTP verification</strong></div>
    <div>Please enter the OTP to confirm changing of your mobile number.</div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      <form
        [formGroup]="otpForm"
        (ngSubmit)="onSubmitOtpForm()"
        class="otpForm"
      >
        <div class="form-group text-center mt12">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 6, allowNumbersOnly: true }"
            [ngClass]=""
            #ngOtpInput
          >
          </ng-otp-input>

          <div *ngIf="invalidOtp" class="error mt20">
            The code entered is incorrect
          </div>
          <span class="px-2 text-center d-block mt12">
            <div [class.red]="this.otpColor == 'red'">
              <span class="contentSmall">
                <countdown
                  #cd
                  [config]="{ leftTime: 60, format: 'mm:ss', notify: 0 }"
                  (event)="handleEvent($event)"
                ></countdown> </span
              ><span> left </span>
            </div>
          </span>

          <span class="px-2 text-center d-block mt40"
            >I didnt receive the code.<span class="blue">Resend OTP</span></span
          >
        </div>

        <div class="text-center mt50">
          <button class="btn btn-primary me-2" type="submit">
            <strong>Confirm</strong>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
<section class="success-screen" *ngIf="showSuccessScreen">
  <div class="success-btn">
    <img class="success-icon" src="../../../assets/images/successIcon.svg" />
  </div>
  <div class="text-center blue">
    <h4><strong> You have successfully changed your mobile number.</strong></h4>
  </div>
  <div class="text-center mt50">
    <button class="btn btn-primary me-2" (click)="onClickLogin()">
      <strong>Back to account settings.</strong>
    </button>
  </div>
</section>
