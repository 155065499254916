import { Component, OnInit, ViewChild } from "@angular/core";
import { environment as env } from "../../../../src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BugsnagService } from "src/app/services/bugsnag.service";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  customerName: string;
  showEyeCloseIcon: boolean = true;
  showPasswordText: boolean = false;
  showEyeOpenIcon: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private bsService: BugsnagService
  ) {}
  @ViewChild("popupDelete", { static: true }) popupDelete: ModalDirective;
  @ViewChild("popupPasswordToDelete", { static: true })
  popupPasswordToDelete: ModalDirective;

  ngOnInit(): void {
    this.getCustomer();
  }
  onClickDeleteBtn() {
    this.popupPasswordToDelete.show();
  }

  openClickDeleteDialog() {
    this.popupDelete.show();
  }
  onClickClose() {
    this.popupDelete.hide();
    this.popupPasswordToDelete.hide();
  }
  onClickEyeCloseIcon() {
    this.showEyeCloseIcon = false;
    this.showPasswordText = true;
    this.showEyeOpenIcon = true;
  }

  onClickEyeOpenIcon() {
    this.showPasswordText = false;
    this.showEyeCloseIcon = true;
    this.showEyeOpenIcon = false;
  }

  customerData: any;
  loyaltyPoints: any;
  customerNamePresent: any = false;

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }
}
